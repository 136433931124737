import React from 'react';
import { IonInput, IonItem, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import './Input.css';

interface GalaxyInputProps {
  label: string;
  labelProps?: any;
  inputProps?: any;
  value: any;
  onChange: (v: any) => void;
  validate?: boolean;
  messageError?: string;
  readonly?: boolean;
}

export const GalaxyInput:React.FC<any> = (props: GalaxyInputProps) => (
  <>
    <IonItem
      className={classNames('form-item', {
        'input-error': props.validate === false,
      })}
      {...props}
    >
      <IonLabel
        color="primary"
        position="stacked"
        className="input-label"
        {...props.labelProps}
      >
        {props.label}
      </IonLabel>
      <IonInput
        autofocus
        value={props.value}
        onIonChange={(e: any) => props.onChange(e.target.value)}
        readonly={props.readonly}
        {...props.inputProps}
      />
    </IonItem>
    {props.validate === false && (
      <IonLabel position="stacked" className="error-label">
        {props.messageError}
      </IonLabel>
    )}
  </>
);

export default GalaxyInput;