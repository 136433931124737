import * as React from "react";
import { IonItem } from "@ionic/react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import "./index.css";

interface GalaxyAccordionProps {
  key: any;
  closeMenu: () => void;
  item: {
    path: string;
    icon: any;
    description: string;
    active?: boolean;
    subItems?: any[];
  };
  menuOpen: boolean;
}

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent !important",
    borderRadius: "15px 15px 0 0",
    padding: 0,
    margin: 0,
    color: "#FFF !important",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    maxWidth: "115px",
    borderRadius: "15px 15px 0 0",
    padding: 0,
    minHeight: "49px",
    "&$expanded": {
      height: "49px",
      minHeight: "49px",
      padding: 0,
      margin: 0
    }
  },
  content: {
    margin: 0,
    "&$expanded": {
      padding: 0,
      margin: 0
    }
  },
  expandIcon: {
    color: "#FFF !important",
    padding: "0 !important"
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    paddingLeft: "10px",
    transform: "translateX(-20px)",
    margin: 0,
    borderLeft: "1px solid #FFF",
    display: "flex",
    alignItems: "center"
  }
  }))(MuiAccordionDetails);

const GalaxyAccordion: React.FC<GalaxyAccordionProps> = ({
  key,
  closeMenu,
  item,
  menuOpen
}) => {
  return (
    <IonItem
      key={key}
      button
      detail={false}
      class={`accordion-item ${item.active && "isActive"}`}
    >
      <item.icon className={"GalaxyAccordion__icon"} />
      <Accordion className="GalaxyAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          {menuOpen && item.description}
        </AccordionSummary>
        {item.subItems &&
          item.subItems.map((elem, index) => (
            <AccordionDetails key={index}>
              <p className='GalaxyAccordion_sublink --dot'> • </p>
              <IonItem
                className={`GalaxyAccordion_sublink ${elem.active && "isActiveSubLink"}`}
                routerLink={elem.path}
                onClick={() => closeMenu()}
              >
                {`${elem.description}`}
              </IonItem>
            </AccordionDetails>
          ))}
      </Accordion>
    </IonItem>
  );
};

export default GalaxyAccordion;
