import React from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonMenuButton,
} from "@ionic/react";
import { personCircleOutline, gridSharp } from "ionicons/icons";
import classNames from "classnames";
import "./TopBar.css";
import GalaxyRolesTooltip from "../GalaxyRolesTooltip";

interface GalaxyTopbarProps {
  show?: boolean;
  children?: any;
  applicationName: string;
  pageName: string;
  onClickAppButton: () => any;
}

interface AvatarProps {
  name: string;
  role: string | string[];
}

interface RolesComponentProps {
  roles: string | string[]
}

const isStringType = (data: string | string[]) => typeof data === "string";

const RolesComponent = (props: RolesComponentProps) => {
  if (isStringType(props.roles)) {
    return <span className="GalaxyTopAvatar__title__role">{props.roles}</span>
  }
  const rolesQty = props.roles.length
  if (rolesQty > 1){
    return (
      <GalaxyRolesTooltip roles={props.roles}>
        <span className="GalaxyTopAvatar__title__role hasMultipleRoles">Ver roles</span>
      </GalaxyRolesTooltip>
    )
  }
  return <span className="GalaxyTopAvatar__title__role">{props.roles[0]}</span>
};

export const GalaxyTopBarAvatar = (props: AvatarProps) => (
  <>
    <IonTitle className="GalaxyTopAvatar__title">
      <span className="GalaxyTopAvatar__title__fullName"> {props.name}</span>{" "}
      <br />
    <RolesComponent roles={props.role} />
    </IonTitle>
    <IonButton disabled className="GalaxyTopAvatar__avatar">
      <IonIcon
        icon={personCircleOutline}
        className={"GalaxyTopAvatar__avatar__icon"}
      />
    </IonButton>
  </>
);

export const GalaxyTopBarButton = (props: any) => (
  <IonButton className="GalaxyTopBar__button" {...props}>
    {props.children}
  </IonButton>
);

export const GalaxyTopBar = (props: GalaxyTopbarProps) => (
  <IonHeader
    mode={"ios"}
    class={classNames({ GalaxyTopbar__hidden: props.show === false })}
  >
    <IonToolbar class={"GalaxyTopBar__toolbar"}>
      <IonMenuButton className="hamburgerMenuButton" slot="start" ></IonMenuButton>
      <IonTitle className="GalaxyTopBar__title">
        <span className="GalaxyTopBar__title__appName">
          {props.applicationName}
        </span>
        <br />
        <span className="GalaxyTopBar__title__pageName">{props.pageName}</span>
      </IonTitle>
      <IonButtons slot="end" className="GalaxyTopBar__buttonsGroup">
        <IonButton
          className="GalaxyTopBar__appsButton"
          color="primary"
          onClick={props.onClickAppButton}
        >
          <IonIcon
            className="GalaxyTopBar__appsIcon"
            icon={gridSharp}
          ></IonIcon>
        </IonButton>
        {props.children}
      </IonButtons>
    </IonToolbar>
  </IonHeader>
);
