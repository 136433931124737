import * as React from 'react';
import { useRef } from 'react';
import { IonModal, IonButton, IonIcon, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import './Modal.css';
import classNames from 'classnames';

type GalaxyModalProps  = {
    show: boolean;
    title: string;
    customClasses?: string;
    menu?: any;
    children?: any;
    modalProps?: any;
}

export const GalaxyModal:React.FC<GalaxyModalProps> = (props:GalaxyModalProps) => {
    const modalRef = useRef<HTMLIonModalElement>(null)

    return (
        <>
            <IonModal backdropDismiss={false} isOpen={props.show} {...props.modalProps} ref={modalRef} className={`GalaxyModal ${ props.customClasses}`}>
                <div>
                    <IonToolbar className="GalaxyModal__toolbar">
                        <IonTitle className={classNames("GalaxyModal__title", {
                          "GalaxyCardMenu__item_hasIcon": !!props.menu
                        })}> {props.title}</IonTitle>
                        <IonButtons slot="end">
                            { props.menu }
                            <IonButton 
                                className="closeButton"
                                onClick={()=> {
                                    if (modalRef.current) { 
                                        modalRef.current.dismiss();
                                    }
                                }} 
                                shape="round"
                            >
                                <IonIcon name='close' />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    {props.children}
                </div>
            </IonModal>
        </>
    )
}

export default GalaxyModal;