import React from "react";
import { toast } from "react-toastify";
import "./GalaxyToast.css";
import { ReactComponent as Warn } from "./icons/warn.svg";
import { ReactComponent as Success } from "./icons/success.svg";
import { ReactComponent as Notification } from "./icons/notification.svg";
import { ReactComponent as Info } from "./icons/info.svg";
import { ReactComponent as Error } from "./icons/error.svg";

interface CustomToastProps {
  closeToast?: () => void;
  header: string;
  message?: string | undefined;
  icon: JSX.Element;
}

const CustomToast: React.FC<CustomToastProps> = props => {
  return (
    <div className="pop-message">
      <div className="pop-message__icon">{props.icon}</div>
      <div className="pop-message__header">{props.header}</div>
      <div className="pop-message__message">{props.message}</div>
    </div>
  );
};

interface GalaxyToastProps {
  header: string;
  message?: string;
  type?: "success" | "warn" | "error" | "info" | undefined;
}

export const GalaxyToast = ({ header, message, type }: GalaxyToastProps) => {
  switch (type) {
    case "success":
      return toast.success(
        <CustomToast header={header} message={message} icon={<Success />} />
      );
    case "warn":
      return toast.warn(
        <CustomToast header={header} message={message} icon={<Warn />} />
      );
    case "error":
      return toast.error(
        <CustomToast header={header} message={message} icon={<Error />} />
      );
    case "info":
      return toast.info(
        <CustomToast header={header} message={message} icon={<Info />} />
      );
    default:
      return toast(
        <CustomToast
          header={header}
          message={message}
          icon={<Notification className="default-icon" />}
        />
      );
  }
};

export default GalaxyToast;
