import React from 'react';
import './IconButton.css'
import { IonButton } from '@ionic/react';
import { Tooltip } from '@material-ui/core'

export const RoundButton:React.FC<any> = React.forwardRef((props:any, ref) => {
    return (
        <IonButton 
            className="GalaxyIconButton"
            {...props}
            ref={ref}
            >
                <div className="GalaxyIconButton-icon">
                    <props.icon className="" />
                </div>
        </IonButton>
    )
})

export const GalaxyIconButton:React.FC<any> = (props:any) => {
    if(props.toolTipIsShown){
        return (
            <Tooltip title={props.toolTipTitle} arrow className="GalaxyIconButton">
                <RoundButton {...props}/>
            </Tooltip>
        )
    }
    return <RoundButton {...props}/>
}

export default GalaxyIconButton;