import * as React from 'react';
import { IonButton  } from '@ionic/react';
import './Button.css';

export const GalaxyButton:React.FC<any> = (props:any) => (
    <IonButton 
        className="galaxyButton"
        shape="round"
        size="default"
        {...props}
    />
)

export default GalaxyButton;