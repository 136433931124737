import React from 'react';
import './RolesTooltip.css';
import { Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const tooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f5',
        color: '#424242',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        borderRadius: 10,
        padding: '1em',
    },
    arrow: {
        color: '#f5f5f5',
    },
}))

const chipStyles = makeStyles(() => ({
    customChip: {
        marginBottom: "0.5em",
    },
}));

const rolesComponent = (roles: string[]) => {
    const classes = chipStyles();
    return (
        <div className="rolesComponentContainer">
            {roles.map((rol: string) => <Chip className={classes.customChip} label={rol} variant="outlined" color="secondary" />)}
        </div>
    )
};

interface GalaxyRolesTooltipProps {
    children?: any;
    roles: string[];
}

export const GalaxyRolesTooltip: React.FC<any> = (props: GalaxyRolesTooltipProps) => {
    const [open, setOpen] = React.useState(false);

    const classes = tooltipStyles();

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                classes={classes}
                title={rolesComponent(props.roles)}
                arrow
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <div onClick={handleTooltipOpen}>
                    {props.children}
                </div>
            </Tooltip>
        </ClickAwayListener>

    )
}


export default GalaxyRolesTooltip;