import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const GalaxyToastContainer: React.FC = props => {
  return (
    <ToastContainer
      position="top-right"
      className="pop-message-container"
      autoClose={5000}
      hideProgressBar
      closeOnClick
      pauseOnHover
      {...props}
    />
  );
};

export default GalaxyToastContainer;
