import * as React from 'react';
import { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { GalaxyButton } from '../GalaxyButton';

import './GalaxyNotification.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

type NotificationItem = {
  title: string;
  description: string;
  createdAt: string;
  buttonName: string;
  buttonAction: Function;  
}

type GalaxyNotificationProps  = {
    notifications: NotificationItem[];
    customClasses?: string;
    children?: any,
    shouldDeleteAfterAction: boolean
}

export const GalaxyNotification:React.FC<GalaxyNotificationProps> = 
  (props: GalaxyNotificationProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [ wait, setWait ] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderButtonAction = (buttonAction: Function, buttonName: string, index: number) => {
    if(!!buttonAction && !!buttonName) {
      const key = Math.round(9000000 * Math.random());
      return (
        <GalaxyButton key={key} disabled={wait} onClick={
            async () => {              
              if(!wait) {
                setWait(true);
                await buttonAction();
                if(props.shouldDeleteAfterAction) {
                  props.notifications.splice(index, 1);
                }
              }
              setTimeout(() => {
                setWait(false);
              }, 1000);
            }
          }>
          {buttonName}
        </GalaxyButton>
      );
    }
    return null;
  }

  const renderNotifications = () => {
    let result: any = null;
    if(!!props.notifications && props.notifications.length > 0) {
      result = (
        <>
          {props.notifications.map((item, i) => {
            const key = i + Math.round(100000 * Math.random());
            return (<>
              <ListItem key={key}>
                <div className="item-notification-container">
                  <div className="item-notification-inner">
                    <FiberManualRecordIcon className="notification-icon-type" />              
                  </div>
                  <div className="item-notification-inner">
                    <span className="item-notification-inner-title">{item.title}</span>
                    <p className="item-notification-inner-body">{item.description}</p>
                    <span className="item-notification-inner-footer">{item.createdAt}</span>
                  </div>
                  <div className="item-notification-inner">
                    {renderButtonAction(item.buttonAction, item.buttonName, i)}
                  </div>
                </div>
              </ListItem>
              <Divider />
            </>);
          })}
        </>);
    }
    return result;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  const renderContent = () => {
    if(!!props.notifications && props.notifications.length > 0) {
      const title = `Tienes ${props.notifications.length} notificaciones sin leer`;
      return (
        <>
        <Tooltip aria-describedby={id}  title={title} aria-label="notiActive">
          <NotificationsActiveIcon
            aria-label="notiActivenotiActive"
            onClick={(event: any) => handleClick(event)}
            color="secondary"
              />	
        </Tooltip>
        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                 anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                 transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <List className={classes.root}>
            <ListItem>
              <ListItemText>
                <span className="notification-header">Notificaciones</span>
              </ListItemText>
              <ListItemSecondaryAction onClick={handleClose}>
                <IconButton>
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider/>
            {renderNotifications()}
          </List>
        </Popover>
      </>);
    } else {
      return (
        <Tooltip title="¡No tienes notificaciones pendientes!" aria-label="notiNone">
				  <NotificationsNoneIcon aria-label="notiNone" />
			  </Tooltip>);
    }
  }

  return renderContent();
}

export default GalaxyNotification;