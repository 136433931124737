import React from 'react';
import classNames from 'classnames';
import './Select.css';
import { IonItem, IonLabel } from '@ionic/react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

interface GalaxySelectProps {
  label: string;
  labelProps?: any;
  inputProps?: any;
  value: any;
  options: any[];
  noOptionsText: string;
  onChange: (v: any, i?: any) => void;
  validate?: boolean;
  messageError?: string;
  readonly?: boolean;
  inputValue: string;
  onInputChange: (e: any, value: any) => void;
  optionLabelAttribute: string;
  autocompleteProps?: any;
}

export const GalaxySelect:React.FC<any> = (props: GalaxySelectProps) => (
  <>
    <IonItem
      className={classNames('form-item', 'autocomplete-item', {
        'input-error': props.validate === false,
        disabled: props.readonly === true,
      })}
    >
      <IonLabel color="primary" position="stacked" className="input-label">
        {props.label}
      </IonLabel>
      <Autocomplete
        noOptionsText={props.noOptionsText}
        options={props.options}
        getOptionLabel={(option: any) =>
          !!option ? `${option[props.optionLabelAttribute]}` : ''
        }
        value={props.value}
        className="galaxy-autocomplete"
        onChange={props.onChange}
        inputValue={props.inputValue}
        onInputChange={props.onInputChange}
        renderOption={(option: any) => (
          <React.Fragment>
            {!!option && !!option[props.optionLabelAttribute]
              ? `${option[props.optionLabelAttribute]}`
              : ''}
          </React.Fragment>
        )}
        disabled={props.readonly}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            variant="standard"
            className="galaxy-autocomplete-label"
            InputProps={props.readonly ? undefined : params.InputProps}
            disabled={props.readonly}
          />
        )}
        {...props.autocompleteProps}
      />
    </IonItem>
    {props.validate === false && (
      <IonLabel position="stacked" className="error-label">
        {props.messageError}
      </IonLabel>
    )}
  </>
);

export default GalaxySelect;